import React from 'react';
import { Box, Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography, IconButton, CircularProgress, Tooltip } from '@mui/material';
import { PlayCircleOutline, PauseCircleOutline, Link, Download } from '@mui/icons-material';
import { storage } from '../../firebase';
import { setupStorageEmulator, parseShowData } from './utils';
import PlaybackDrawer from './PlaybackDrawer';
import { usePlayback } from './PlaybackContext';
import SnackBar from '../snackbar/SnackBar';
import LinkPlayDialog from './LinkPlayDialog';
import DownloadDialog from './DownloadDialog';

// Used for handling which firebase to use Cloud or Emulator
setupStorageEmulator(storage);

// The Set table row
const SetRow = ({ set }) => (
    <TableRow>
        <TableCell align="left" sx={{border: 0}}>
            <Typography variant="h6">{set.title}</Typography>
        </TableCell>
    </TableRow>
);

// The Song table row
const SongRow = ({ song, handleRowSelected, handleCreateLink, handleDownload }) => {
    const { isTrackPlaying, currentSong } = usePlayback();
    const isSelected = currentSong?.track === song.track;
    return (
        <TableRow selected={song.selected}
            sx={{
                backgroundColor: isSelected ? "#111110" : "transparent",
                '&:hover': {
                    backgroundColor: isSelected ? "#111110 !important" : "#0c0c0b !important",
                },
                '@media (hover: none)': {
                    '&:hover': {
                        backgroundColor: 'inherit', // Remove hover effect
                        cursor: 'default',
                    },
                },
            }} 
        >
            <TableCell align="center" style={{width: 100, border: 0, color: "white", cursor: "pointer", fontWeight: "bold"}} onClick={handleRowSelected(song)}>
                {
                    isSelected ?
                    (isTrackPlaying ?
                        <IconButton sx={{ color: "white" }}>
                            <PauseCircleOutline />
                        </IconButton>
                        :
                        <IconButton sx={{ color: "white" }}>
                            <PlayCircleOutline />
                        </IconButton>
                    )
                    :
                    song.track
                }
            </TableCell>
            <TableCell align="left" sx={{border: 0, color: "white", cursor: "pointer"}} onClick={handleRowSelected(song)}><strong>{song.song}</strong></TableCell>
            <TableCell onClick={handleRowSelected(song)}
                sx={{
                    border: 0, 
                    color: "white", 
                    cursor: "pointer", 
                    textAlign: {xs: 'left',sm: 'center'}}
                }
            >
                <strong>{song.duration}</strong>
            </TableCell>
            <TableCell align="center"
                sx={{
                    padding: "0px 20px 0px 20px", 
                    border: 0, 
                    color: "white", 
                    width: "40px",
                    display: {xs: 'none', sm: 'table-cell'}
                }}>
                <Tooltip title="Link"  placement="top" arrow>
                    <IconButton sx={{ color: "white" }} onClick={handleCreateLink(song)}>
                        <Link />
                    </IconButton>
                </Tooltip>
            </TableCell>
            <TableCell align="center"
                sx={{
                    padding: "0px 20px 0px 0px", 
                    border: 0, 
                    color: "white", 
                    width: "40px",
                    display: {xs: 'none', sm: 'table-cell'}
                }}>
                <Tooltip title="Download" placement="top" arrow>
                    <IconButton sx={{ color: "white" }} onClick={handleDownload(song)}>
                        <Download />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    )
};

// Simple circular loader
const Loading = () => (
    <Box sx={{ display: 'inline-flex', position: 'relative', flexGrow: 1, justifyContent: "center" }}>
        <CircularProgress size={80}/>
        <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            <Typography variant="caption" component="div" sx={{color: "white"}}>Loading...</Typography>
        </Box>
    </Box>
);

export default function Setlist({ showData }) {
    const { currentSong, setCurrentSong, onPlayPause, songlist, setSonglist } = usePlayback();
    const [loaded, setLoaded] = React.useState(false);
    const [isPlayerOpen, setIsPlayerOpen] = React.useState(false);
    // Snackbar stuff
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [severity, setSeverity] = React.useState("info");
    // Link Playback Dialog
    const [playDialogOpen, setPlayDialogOpen] = React.useState(false);
    // Download Dialog
    const [downloadDialogOpen, setDownloadDialogOpen] = React.useState(false);
    const [downloadSong, setDownloadSong] = React.useState(null);

    // Hook used to parse the incoming showdata
    React.useEffect(() => {
        if ( !songlist.length ) {
            parseShowData(showData, storage, setSonglist, setCurrentSong)
            .then(() => {
                setLoaded(true);
            })
            .catch(console.error);
        } else {
            // Opeen the link playback dialog if the link data is present
            if( showData.linkData ) setPlayDialogOpen(true);
        }
    }, [showData, songlist.length]);

    // Hook used to play the song if it is selected
    React.useEffect(() => {
        if(currentSong) {
            // Open the playback drawer if it is not open
            if(!isPlayerOpen) setIsPlayerOpen(true);
            onPlayPause();
        } 
    }, [currentSong])

    // Function used to toggle the playback drawer to open up from bottom
    const handleRowSelected = (song) => () => {
        // Play/Pause the song if the row is selected
        if( song === currentSong ) {
            onPlayPause();
            return;
        }
        setCurrentSong(song);
    };

    // Function used to create a shareable link to the song
    const handleCreateLink = (song) => () => {
        let link = `${window.location.href}?showPath=${showData.showPath}&track=${song.track}&song=${song.song}`;
        navigator.clipboard.writeText(encodeURI(link))
        .then(() => {
            // console.log("Link copied to clipboard!");
            setMessage(`Link Copied to Clipboard`);
            setSeverity("success");
        })
        .catch(err => {
            console.error("Failed to copy link: ", err);
            setMessage(`Failed to create link`);
            setSeverity("error");
        })
        .finally(() => {
            setSnackbarOpen(true);
        });
    };

    // Function used to handle playing the song automatically via a copy link
    const handleAutoPlay = (evt) => {
        if( evt.target.textContent === "Yes" ) {
            for(const song of songlist) {
                if( showData.linkData && song.track === showData.linkData.track && song.song === showData.linkData.song ) {
                    setCurrentSong(song);
                }
            }
        }
        setPlayDialogOpen(false);
    };

    // Function used to download the selected song
    const handleDownload = (song) => () => {
        setDownloadDialogOpen(true);
        setDownloadSong(song);

        fetch(song.downloadURL)
        .then(response => response.blob())
        .then(blob => {
            const link = document.createElement('a');
            const blobURL = URL.createObjectURL(blob);
            link.href = blobURL;
            link.download = song.filename || 'download';
            document.body.appendChild(link);
            link.click();
            
            // Clean up
            URL.revokeObjectURL(blobURL);
            link.remove();

            setMessage(`Downloaded '${song.song}'`);
            setSeverity("success");
        })
        .catch(error => {
            console.error("Download failed:", error)
            setMessage(`Failed to download '${song.song}'`);
            setSeverity("error");
        })
        .finally(() => {
            setSnackbarOpen(true);
            setDownloadDialogOpen(false);
            setDownloadSong(null);
        });
    }

    // Makes more bottom margin on the table with the player is open
    const playerOpen = isPlayerOpen ? { marginBottom: { xs: 25, sm: 20  }} : {};

    return (
        <>
            <Grid container item xs={12} sx={{margin: {xs: 0, sm: 1, md: 3}, pt: 2}}>
                {/* Playback */}
                <PlaybackDrawer isPlayerOpen={isPlayerOpen}/>
                
                {   // Song List Table
                    !loaded ? <Loading /> :
                    <TableContainer component={Box}>
                        <Table 
                            sx={{
                                backgroundColor: "black", 
                                ...playerOpen
                            }} 
                        >
                            {
                                songlist.map(item => {
                                    return item.type === 'set' ?
                                            <TableHead key={item.title}>
                                                <SetRow set={item}/>
                                            </TableHead>
                                        :
                                            <TableBody key={item.track} sx={{height: "70px"}}>
                                                <SongRow 
                                                    song={item} 
                                                    handleRowSelected={handleRowSelected} 
                                                    handleCreateLink={handleCreateLink} 
                                                    handleDownload={handleDownload}
                                                />
                                            </TableBody>
                                })
                            }
                        </Table>
                    </TableContainer>
                }
            </Grid>
            {/* SnackBar */}
            <SnackBar
                open={snackbarOpen}
                handleClose={(event, reason) => {
                    if (reason === "clickaway") return;
                    setSnackbarOpen(false);
                }}
                message={message}
                severity={severity}
            />
            {/* Link Play Dialog */}
            {
                !showData.linkData ? null :
                <LinkPlayDialog 
                    open={playDialogOpen} 
                    showData={showData} 
                    handleAutoPlay={handleAutoPlay} 
                    handleClose={() => setPlayDialogOpen(false)}
                />
            }
            {/* Download Dialog */}
            <DownloadDialog 
                open={downloadDialogOpen} 
                handleClose={() => setDownloadDialogOpen(false)}
                song={downloadSong}
            />
        </>
    )
}
