import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function LinkPlayDialog({open, handleClose, showData, handleAutoPlay}) {
    return (
        <React.Fragment>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="play-link-dialog"
            >
                <DialogTitle>
                    Would you like to play <strong>&apos;{showData.linkData.song}&apos;</strong> from 
                    the {showData.venue} in {showData.city} {showData.state}?
                </DialogTitle>
                <DialogActions sx={{justifyContent: "center"}}>
                    <Button onClick={handleAutoPlay}>Yes</Button>
                    <Button onClick={handleClose}>No</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
