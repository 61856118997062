import moment from 'moment';
import { connectStorageEmulator, ref, listAll, getDownloadURL as firebaseGetDownloadURL } from 'firebase/storage';
import { connectFirestoreEmulator } from 'firebase/firestore';
import { connectFunctionsEmulator } from "firebase/functions";
import { connectAuthEmulator } from 'firebase/auth';

// This function simply converts an audio files duration which 
// is in seconds to a hours:minutes:seconds
export function convertDuration(dur) {
    var duration = moment.duration(dur, "seconds");
    var hours = duration.hours();

    let seconds = "";
    if ( /^\d$/.test( duration.seconds() ) ) {
        seconds = "0" + duration.seconds().toString();
    } else {
        seconds = duration.seconds();
    }

    let time = "";
    if ( hours > 0 ) {
        time = `${hours}:${duration.minutes()}:${seconds}`
    } else {
        time = `${duration.minutes()}:${seconds}`
    }

    return time;
}

// This function simply converts and returns the firestore timestamp
// in a common form 
export function timestampToDate(date) {
    return date.toDate().toLocaleDateString();
}

// This function is the same as above but just a different date format
export function timestampToDate2(timestamp) {
    const date = new Date(timestamp.seconds * 1000);
    return date.toLocaleDateString("en-US", {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    });
}

// Instructs the app to use the cloud storage emulator
export const setupStorageEmulator = (storage) => {
    if (process.env.REACT_APP_EMULATOR === "TRUE") {
        console.log("USING STORAGE EMULATOR");
        connectStorageEmulator(storage, "127.0.0.1", 9199);
    }
};

// Instructs the app to use the cloud functions emulator
export const setupFunctionsEmulator = (functions) => {
    // This instructs the app to use the Firebase Cloud Functions emulator
    if ( process.env.REACT_APP_EMULATOR === "TRUE" ) {
        console.log("USING CLOUD FUNCTIONS EMULATOR")
        connectFunctionsEmulator(functions, "127.0.0.1", 5001);
    }
}

// This instructs the app to use the firestore emulator
export const setupFirestoreEmulator = (database) => { 
    if ( process.env.REACT_APP_EMULATOR === "TRUE" ) {
        console.log("USING FIRESTORE EMULATOR")
        connectFirestoreEmulator(database, "127.0.0.1", 8080);
    }
}

// This instructs the app to use the authentication emulator
export const setupAuthEmulator = (auth) => { 
    if ( process.env.REACT_APP_EMULATOR === "TRUE" ) {
        console.log("USING AUTHENTICATION EMULATOR")
        connectAuthEmulator(auth, "http://127.0.0.1:9099", { disableWarnings: true })
    }
}

// Parses the given show data
export async function parseShowData(showData, storage, setSonglist, setCurrentSong) {
    let sList = [];
    
    for(let set of showData.sets) sList.push(...await parseSet(set, showData, storage));

    // This loop is responsible for selecting the first song to auto-play
    /* for(const song of sList) {
        if( song.type === "song" ) {
            setCurrentSong(song);
            break;
        }
    } */

    setSonglist(sList);
}

// Handles parsing the sets
export async function parseSet(set, showData, storage) {
    let sList = [{ title: set.title, type: "set" }];

    for(let song of set.songs) {
        const songObj = await parseSong(song, showData, storage);
        sList.push(songObj);
    }

    return sList;
}

// Handles parsing the song
const regex = /^(\d+|[Ee])\-(\d+)\s(.*)\.(mp3|m4a)$/; // Precompiled RegExp
export async function parseSong(song, showData, storage) {
    let match = song.title.match(regex);
    
    // Get a download url
    const downloadURL = await getDownloadURL(storage, `shows/${showData.showPath}/${song.title}`);
    
    return {
        set: match[1],
        track: match[2].replace(/^0+/, ''),
        song: match[3],
        filename: song.title,
        duration: song.duration,
        type: "song",
        selected: false,
        action: "number",
        downloadURL
    };
}

// Get a download url for the given Cloud Storage path
export const getDownloadURL = async (storage, path) => {
    const listRef = ref(storage, path);
    // console.log(path)
    return await firebaseGetDownloadURL(listRef);
};

// Simple function to regex a filename to see if it is an image
function isImageFile(filename) {
    const pattern = /\.(jpeg|jpg|png|gif|bmp|tiff|tif|webp)$/i; // Corrected for JavaScript
    return pattern.test(filename);
}

// Gets the show image from storage
export const getShowImage = async (showPath, storage) => {
    const listRef = ref(storage, `shows/${showPath}`);
    return listAll(listRef)
    .then(res => {
        let imageFilename = '';
        res.items.forEach(item => {
            if( isImageFile(item.name) ) imageFilename = item.name;
        });
        return imageFilename;
    })
    .then(async (imgFile) => {
        const url = await getDownloadURL(storage, `shows/${showPath}/${imgFile}`);
        return url;
    });
}
