import React from 'react';
import { Grid, Typography } from '@mui/material';
import { UserContext } from '../../contexts/UserContext';

const styles = {
    root: {
        position: "fixed",
        bottom: 0,
        left: 0,
    },
    msg: {
        fontSize: "8pt",
        fontWeight: 900,
        color: "gray"
    }
}

export default function Footer() {
    const userCtx = React.useContext(UserContext);
    const [showFooter, setShowFooter] = React.useState(true);

    // Hook used to display and hide the footer
    /* React.useEffect(() => {
        if ( userCtx.user ) setShowFooter(true);
        else setShowFooter(false);
    }, [setShowFooter, userCtx.user]); */
    
    return !showFooter ? null :
        <Grid container style={styles.root} justify="center">
            <Grid container item xs={2}></Grid>
            <Grid container item xs={8} justifyContent="center">
                <Typography variant="body1" style={styles.msg}>
                    <a style={{textDecoration: "none", color: "gray"}} href="mailto:admin@bombrange.com">PMH Productions</a>
                </Typography>
                <Typography variant="body1" style={{padding: "0px 5px 0px 5px", ...styles.msg}}>&#169;</Typography>
                <Typography variant="body1" style={styles.msg}>{new Date().getUTCFullYear()}</Typography>
                <Typography variant="body1" style={{...styles.msg, paddingLeft: 10, paddingRight: 10}}
                >|</Typography>
                <Typography variant="body1" style={styles.msg}>
                    <a style={{textDecoration: "none", color: "gray"}} href="/privacy">Privacy Policy</a>
                </Typography>
            </Grid>
            <Grid container item xs={2} justifyContent="flex-end">
                <Typography variant="body1" style={{...styles.msg, paddingRight: 5}}>v{process.env.REACT_APP_VERSION}</Typography>
            </Grid>
        </Grid>
}
