import React from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import { Typography, Grid, Paper } from "@mui/material";

export default function Pictures() {
    const userCtx = React.useContext(UserContext);
    const navigate = useNavigate();

    /* React.useEffect(() => {
        // console.log("User : ", userCtx.user)

        if ( !userCtx.user ) navigate("/signin");
    }, [userCtx.user, navigate]) */

    return(
        <Grid container sx={{pt: 3}} justifyContent="center">
            <Grid item xs={11} md={10}>
                <Paper square={false} variant="outlined" sx={{opacity: "0.98", mb: 3, p: {xs: 2, sm: 3}, color: "white"}}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography gutterBottom variant="h4">
                                Pictures
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">
                                Coming Soon.
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}
