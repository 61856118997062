import React, { createContext, useContext, useRef, useState, useCallback, useEffect } from 'react';

const PlaybackContext = createContext();
export const usePlayback = () => useContext(PlaybackContext);

export const PlaybackProvider = ({ children }) => {
    const audioRef = useRef(null);
    const [isTrackPlaying, setIsTrackPlaying] = useState(false);
    const [currentSong, setCurrentSong] = useState(null);
    const [elapsedTime, setElapsedTime] = useState(0);
    const [remainingTime, setRemainingTime] = useState(0);
    const [songlist, setSonglist] = useState([]);

    // Play/Pause handler with cleanup
    const onPlayPause = useCallback(() => {
        const audio = audioRef.current;

        if (audio) {
            if (audio.paused) {
                audio.play().catch((err) => {
                    // console.log('Callback');
                    // console.error("Play interrupted:", err)
                });
                setIsTrackPlaying(true);
            } else {
                audio.pause();
                setIsTrackPlaying(false);
            }
        }
    }, []);

    // Handle track change and ensure cleanup
    const changeTrack = useCallback((trackChange) => {
        if (currentSong) {
            const nextTrack = +currentSong.track + trackChange;
            const nextSong = songlist.find(song => song.track === nextTrack.toString());

            if (nextSong) setCurrentSong(nextSong);
        }
    }, [currentSong, songlist]);

    // Update playback time
    const updateTime = (e, value) => {
        if (audioRef.current) audioRef.current.currentTime = value;
    };

    // Handle time updates during playback
    const onTimeUpdate = useCallback((e) => {
        const audio = e.target;
        setElapsedTime(audio.currentTime);
        setRemainingTime(audio.duration - audio.currentTime);
    }, []);

    // Ensure playback starts when a new song is set
    useEffect(() => {
        const audio = audioRef.current;

        if (audio && currentSong) {
            // Set the source and load the new song
            audio.src = currentSong.downloadURL;
            audio.load();

            // Wait for audio to be ready before playing
            const onCanPlay = () => {
                audio.play().catch((err) => {
                    // console.log('Hook');
                    // console.error("Play error:", err)
            });
                setIsTrackPlaying(true);
            };

            audio.addEventListener('canplay', onCanPlay);

            // Cleanup previous listeners
            return () => {
                audio.pause();
                audio.removeEventListener('canplay', onCanPlay);
                setIsTrackPlaying(false);
            };
        }
    }, [currentSong]);

    return (
        <PlaybackContext.Provider
            value={{
                audioRef,
                isTrackPlaying,
                currentSong,
                setCurrentSong,
                onPlayPause,
                changeTrack,
                elapsedTime,
                remainingTime,
                songlist,
                setSonglist,
                updateTime,
            }}
        >
            {children}
            {currentSong && (
                <audio
                    ref={audioRef}
                    onEnded={() => changeTrack(1)}
                    onTimeUpdate={onTimeUpdate}
                />
            )}
        </PlaybackContext.Provider>
    );
};
