import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DownloadDialog({open, handleClose, song}) {
    return ( song === null ? null :
        <React.Fragment>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="download-dialog"
                sx={{padding: 30}}
            >
                <DialogTitle sx={{textAlign: "center"}}>
                    Downloading
                </DialogTitle>
                <DialogContent>
                    <Box sx={{display: "flex", justifyContent: "center"}}>
                        <CircularProgress size="115px" />
                    </Box>
                </DialogContent>
                <DialogTitle sx={{textAlign: "center"}}>
                    {song.song}
                </DialogTitle>
            </Dialog>
        </React.Fragment>
    );
}
