import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { signInWithPopup, GoogleAuthProvider, getIdTokenResult } from "firebase/auth";
import { auth } from "../../firebase";

export default function SignedOutLinks({setMessage, setSeverity, setSnackbarOpen}) {

    // Function to sign in the user using a Google Popup
    const signIn = async () => {
        try {
            // Use Google as the main auth provider
            const provider = new GoogleAuthProvider();
            const result = await signInWithPopup(auth, provider);

            // Get the user claims
            const claims = (await getIdTokenResult(result.user)).claims;
            if ( !claims.approved ) {
                setMessage(`Processing user ${result.user.displayName}`);
                setSeverity("info");
            } else {
                setMessage(`Welcome back ${result.user.displayName}`);
                setSeverity("success");
            }
        } catch ( error ) {
            // Handle specific errors
            let errorMsg = "An error occurred during sign in.";
            if (error.code === "auth/popup-closed-by-user") errorMsg = "User closed popup";
            if (error.code === "auth/cancelled-popup-request") errorMsg = "Sign in canceled";
            if (error.code === "auth/user-disabled") errorMsg = "Account Disabled";

            setMessage(errorMsg);
            setSeverity("error");
        } finally {
            setSnackbarOpen(true);
        }
    };

    return (
        <Box>
            <Button variant="text" onClick={signIn}>
                <Typography variant="h6" style={{ color: "whitesmoke", fontSize: "17px" }}>
                    Login
                </Typography>
            </Button>
        </Box>
    );
}
