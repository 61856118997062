import React from 'react';
import { Card, Typography, Grid, Box, Skeleton } from '@mui/material';
import { timestampToDate2, getShowImage } from './utils';

export default function ShowCard({ show, setSelectedShow, storage }) {
    const [imageUrl, setImageUrl] = React.useState('');

    // Hook used to load the image from cloud storage on page load
    React.useEffect(() => {
        getShowImage(show.showPath, storage)
        .then(url => {
            setImageUrl(url);
        });
    }, [show.showPath]);
    
    return (
        <Card 
            sx={{
                m: 2, 
                bgcolor: '#3a3f43',
                '&:hover': {
                    bgcolor: '#585858'
                }}} 
                onClick={() => setSelectedShow({show: {...show, linkData: undefined}, imageUrl})
            }
        >
            <Grid container direction="column">
                <Grid item>
                    {
                        imageUrl === '' ? <Skeleton variant="rectangular" sx={{
                            maxWidth: {
                                xs: "115px",
                                sm: "175px",
                                md: "225px"
                            },
                            width: {
                                xs: "115px",
                                sm: "175px",
                                md: "225px"
                            },
                            height: {
                                xs: "115px",
                                sm: "175px",
                                md: "225px"
                            },
                            m: 2
                        }}/> :
                            <Box 
                                component="img"
                                src={imageUrl}
                                alt='venue'
                                sx={{
                                    minHeight: {
                                        xs: "115px",
                                        sm: "175px",
                                        md: "230px"
                                    },
                                    width: {
                                        xs: "115px",
                                        sm: "175px",
                                        md: "225px"
                                    },
                                    maxWidth: {
                                        xs: "115px",
                                        sm: "175px",
                                        md: "225px"
                                    },
                                    m: 2
                                }}
                            />
                    }
                </Grid>
                <Grid container item justifyContent="center" direction="column" alignContent="center" sx={{
                    mb: 2,
                    alignItems: {
                        xs: "center",
                        md: "flex-start"
                    }
                }}>
                    <Typography variant='h6'>{show.venue}</Typography>
                    <Typography variant='subtitle1'>{`${show.city}, ${show.state}`}</Typography>
                    <Typography variant='body1'><strong>{timestampToDate2(show.date)}</strong></Typography>
                </Grid>
            </Grid>
        </Card>
    )
}