import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, IconButton, Drawer, Typography, Slider } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faForwardStep, faCirclePlay, faBackwardStep, faCirclePause } from '@fortawesome/free-solid-svg-icons';
import { usePlayback } from './PlaybackContext';
import { convertDuration } from './utils';

const TinyText = styled(Typography)({
    fontSize: '0.75rem',
    opacity: 0.38,
    fontWeight: 500,
    letterSpacing: 0.2,
});

export default function PlaybackDrawer({ isPlayerOpen }) {
    const { audioRef, currentSong, onPlayPause, isTrackPlaying, changeTrack, elapsedTime, remainingTime, updateTime } = usePlayback();
    return (
        <Drawer 
            variant='persistent' 
            anchor="bottom" 
            open={isPlayerOpen}
            ModalProps={{ keepMounted: true, BackdropProps: { invisible: true } }}
        >
            <Grid sx={{ width: 'auto', padding: 2 }} role="presentation">
                <Grid container justifyContent="center">
                    <Grid container item justifyContent="center" sx={{pb: 2}}>
                        <Typography variant='body1' sx={{fontWeight: "bold"}}>{currentSong?.song}</Typography>
                    </Grid>

                    {/* Media Controls */}
                    <Grid container item justifyContent="center" alignItems="center" spacing={3} sx={{mb: 1}}>
                        <Grid item>
                            <IconButton onClick={() => changeTrack(-1)}>
                                <FontAwesomeIcon className="icon-hover" icon={faBackwardStep} color="whitesmoke" style={{height: "30px", cursor: "pointer"}}/>
                            </IconButton>
                        </Grid>
                        <Grid item>
                        {
                            !isTrackPlaying ? 
                                <IconButton onClick={() => onPlayPause()}>
                                    <FontAwesomeIcon className="icon-hover" icon={faCirclePlay} color="whitesmoke" style={{height: "35px", cursor: "pointer"}}/>
                                </IconButton>
                            :
                                <IconButton onClick={() => onPlayPause()}>
                                    <FontAwesomeIcon className="icon-hover" icon={faCirclePause} color="whitesmoke" style={{height: "35px", cursor: "pointer"}}/>
                                </IconButton>
                        }
                        </Grid>
                        <Grid item>
                            <IconButton onClick={() => changeTrack(1)}>
                                <FontAwesomeIcon className="icon-hover" icon={faForwardStep} color="whitesmoke" style={{height: "30px", cursor: "pointer"}}/>
                            </IconButton>
                        </Grid>
                    </Grid>

                    {/* Timeline */}
                    <Grid container item xs={11} md={8} direction="row" spacing={2} alignItems="center" sx={{pb: 1}}>
                        <Grid item sx={{mb: "4px"}}>
                            <TinyText>{convertDuration(elapsedTime)}</TinyText>
                        </Grid>
                        <Grid item xs>
                            <Slider
                                onChange={(e, value) => updateTime(e, value)}
                                value={audioRef.current?.currentTime || 0} 
                                max={audioRef.current?.duration || 0}
                                min={0}
                                sx={{
                                    '& .MuiSlider-track': {
                                        backgroundColor: '#FFB90D',
                                        border: "1px solid #FFB90D",
                                    },    
                                    // "& .MuiSlider-thumb": {
                                    //     backgroundColor: "transparent !important"
                                    // },
                                    // "& .MuiSlider-thumb:hover": {
                                    //     backgroundColor: "currentColor !important"
                                    // },
                                }}
                            /> 
                        </Grid>
                        <Grid item sx={{mb: "4px"}}>
                            <TinyText>{remainingTime ? convertDuration(remainingTime) : currentSong?.duration}</TinyText>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Drawer>
    )
}
